import React from "react";
import Dashboard from "./pages/Dashboard/Dashboard";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./pages/user-pages/Login";
import { ToastContainer, toast } from "react-toastify";


const  App  = () => {
  return (
    <BrowserRouter>
    <ToastContainer autoClose={8000} />
    <Routes>
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="login" element={<Login />} />
        {/* <Route path="add-test" element={<Tests />} /> */}
    </Routes>
  </BrowserRouter>
  );
}
export default App;
