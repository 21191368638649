import React from "react";
import axios from "axios";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate();

  const navToVerify = async (e) => {
    e.preventDefault();

    try {
      const {data} = await axios.post("https://golet.jargeh.com/golet/api/auth/employee/login", {
        mobile: mobile,
        password: password
      });
      localStorage.setItem('TOKEN', data.success.token)
      console.log(data.success.token);
      toast.success("با موفقیت وارد شدید", { autoClose: 15000 });
      navigate("/dashboard");
    } catch (e) {
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center auth mt-4">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <ToastContainer autoClose={8000} />
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo text-center">
                <img
                  src={require("../../assets/images/auth/think.png")}
                  alt="logo"
                />
              </div>
              <h6 className="font-weight-bold text-center">ورود به داشبورد</h6>
              <Form className="pt-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block"
                    onClick={navToVerify}
                  >
                    ورود
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
