import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © Jargeh 2022</span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">برنامه نویسی شده توسط تیم جرگه</span>
      </div>
    </footer> 
  );
}


export default Footer;