import React, { useEffect, useState } from "react";
import "./Form.css";
import "./Diseases.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";
import { NavLink } from "react-router-dom";

const Plants = () => {
  const token = localStorage.getItem("TOKEN");

  const [allPlants, setAllPlants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchPlants = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/plant/get-plants-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllPlants(data.plantsList);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPlants();
  }, []);

  const deletePlant = async (plant_id, e) => {
    e.preventDefault();
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/website/plant/delete/${plant_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const activePost = async (idPost) => {
    console.log(idPost);
    try {
      const api = `${process.env.REACT_APP_URL}/website/plant/change-show-status`;
      const { data } = await axios.post(
        api,
        {
          plant_id: idPost,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(data);
      toast.success(data.message, { autoClose: 15000 });
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };
  
  const handleCheck = (setStatus) => {
    if(setStatus == 'publish'){
      return true
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <h3 className="mb-4">گل و گیاهان</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="table-wrap">
                <table className="table">
                  <thead className="thead-primary">
                    <tr>
                      <th>عنوان گیاه</th>
                      <th>اسم انحصاری</th>
                      <th>وضعیت</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPlants.map((item) => {
                      return (
                        <tr>
                          <td>
                            <NavLink
                              key={item.plant_id}
                              to={`/dashboard/plant/${item.plant_id}`}
                            >
                              {item.title}
                            </NavLink>
                          </td>
                          <td>{item.unique_name}</td>
                          <td>
                            <input
                              className="checkHeight"
                              defaultChecked={handleCheck(item.show_status)}
                              type="checkbox"
                              value={item.show_status}
                              id="defaultCheck1"
                              onChange={() => activePost(item.plant_id)}
                            />
                          </td>
                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.plant_id}
                              to={`/dashboard/plant/${item.plant_id}`}
                            >
                              <img
                                className="imgS"
                                src={require("../../assets/images/explain/arrow-left.png")}
                                alt="logo"
                              />
                            </NavLink>
                          </td>

                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.plant_id}
                              to={`/dashboard/faq-plant/${item.plant_id}`}
                            >
                              افزودن FAQ
                            </NavLink>
                          </td>

                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.plant_id}
                              to={`/dashboard/add-plant-info/${item.plant_id}`}
                            >
                              افزودن اطلاعات
                            </NavLink>
                          </td>

                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.plant_id}
                              to={`/dashboard/add-plant-propagation/${item.plant_id}`}
                            >
                              افزودن اطلاعات تکثیر
                            </NavLink>
                          </td>

                          <td className="text-left">
                            <img
                              onClick={(e) => {
                                deletePlant(item.plant_id, e);
                              }}
                              className="imgS"
                              src={require("../../assets/images/explain/delete.png")}
                              alt="logo"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Plants;
