import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const Disease = () => {
  const URL = "https://golet.jargeh.com/golet/";
  const token = localStorage.getItem("TOKEN");

  const { id } = useParams();
  const navigate = useNavigate();

  const [uniqueName, setUniqueName] = useState("");
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [summary, setSummary] = useState();
  const [commonName, setCommonName] = useState();
  const [causes, setCauses] = useState();
  const [treatment, setTreatment] = useState();
  const [enName, setEnName] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [productUrl, setProductUrl] = useState();
  const [symptoms, setSymptoms] = useState();
  const [allDisease, setAllDisease] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);

  const [allPlants, setAllPlants] = useState([]);
  const [plantsFill, setPlantsFill] = useState([]);

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchPlants = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/plant/get-plants-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllPlants(data.plantsList);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPlants();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchDisease = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/disease/get-disease/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setAllDisease(data.diseaseInfo);
        setEditImages(data.gallery);
        setIsLoading(false);
        console.log(allDisease);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchDisease();
  }, []);

  useEffect(() => {
    setUniqueName(allDisease.unique_name);
    setTitle(allDisease.title);
    setCommonName(allDisease.common_name);
    setEnName(allDisease.en_name);
    setSummary(allDisease.summary);
    setDescription(allDisease.description);
    setCauses(allDisease.causes);
    setTreatment(allDisease.treatment);
    setVideoUrl(allDisease.video_url);
    setImage(allDisease.image);
  }, [allDisease]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  console.log(images);

  const editDisease = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);
    formData.append("disease_id", id);

    images.forEach((image) => {
      formData.append("gallery[]", image);
    });

    plantsFill.forEach((plantFill) => {
      formData.append("plantsId[][plant_id]", plantFill.plant_id);
    });

    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/disease/edit`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setIsLoading(false);
        navigate("/dashboard/diseases/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  const deleteImage = async (imgId, e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/website/disease/delete-image/${id}/${imgId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditImages((images) => images.filter((img) => img.id !== imgId));
      toast.success(data.message, { autoClose: 15000 });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer autoClose={8000} />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                ویرایش بیماری
              </h2>
              <hr className="hrDisease" />

              <div className="form-row mt-4">
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام اختصاصی بیماری
                  </label>
                  <input
                    value={uniqueName}
                    type="text"
                    className="form-control"
                    name="unique_name"
                    placeholder="نام اختصاصی بیماری"
                    required
                    onChange={(e) => setUniqueName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان بیماری
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان بیماری"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام متداول
                  </label>
                  <input
                    value={commonName}
                    type="text"
                    className="form-control"
                    name="common_name"
                    placeholder="نام متداول"
                    required
                    onChange={(e) => setCommonName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام انگلیسی بیماری
                  </label>
                  <input
                    value={enName}
                    type="text"
                    className="form-control"
                    name="en_name"
                    placeholder="نام انگلیسی بیماری"
                    required
                    onChange={(e) => setEnName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    خلاصه
                  </label>
                  <textarea
                    value={summary}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="summary"
                    placeholder="خلاصه"
                    required
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    علائم بیماری
                  </label>
                  <textarea
                    value={symptoms}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="symptoms"
                    placeholder="علائم بیماری"
                    required
                    onChange={(e) => setSymptoms(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    علت بیماری
                  </label>
                  <textarea
                    value={causes}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="causes"
                    placeholder="علت بیماری"
                    required
                    onChange={(e) => setCauses(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    نحوه درمان
                  </label>
                  <textarea
                    value={treatment}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="treatment"
                    placeholder="نحوه درمان"
                    required
                    onChange={(e) => setTreatment(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold">تصویر شاخص</label>
                  <input className="inputWidth100" type="file" name="image" />
                  <div className="imgFormResize mt-3">
                    <img src={URL + allDisease.image} />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  <div className="row">
                    {console.log(editImages)}
                    {editImages.map((img) => {
                      return (
                        <div>
                          <div className="imgFormResize text-center mb-2 mt-3">
                            <img src={URL + img.address} />
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              deleteImage(img.id, e);
                            }}
                          >
                            حذف
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    {images &&
                      images.map((img) => {
                        return <p className="mr-4">{img?.name}</p>;
                      })}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                    value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    required
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                  لینک خرید محصول
                  </label>
                  <input
                    value={productUrl}
                    type="text"
                    className="form-control"
                    name="recommended_product_url"
                    placeholder="لینک خرید محصول"
                    required
                    onChange={(e) => setProductUrl(e.target.value)}
                  />
                </div>
              </div>

              <hr />

              <div className="form-row mb-2">
                <label className="font-weight-bold" for="">
                  گیاهان مرتبط با این بیماری
                </label>
                {allPlants.map((t, index) => {
                  return (
                    <div className="col-md-12" key={t.plant_id}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => {
                            if (e.target.checked) {
                              <input type="text" />;
                              setPlantsFill([
                                ...plantsFill,
                                {
                                  plant_id: t.plant_id,
                                },
                              ]);
                            } else {
                              // remove from list
                              setPlantsFill(
                                plantsFill.filter(
                                  (people) => people.plant_id !== t.plant_id
                                )
                              );
                            }
                          }}
                          value={plantsFill}
                        />

                        <label className="form-check-label">{t.title}</label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={editDisease}
              >
                ویرایش
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Disease;
