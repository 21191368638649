import React, { useEffect, useState } from "react";
import "./Form.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const AddProduct = () => {
  const [productType, setProductType] = useState([]);
  const [productTypeId, setProductId] = useState();
  const [allDiseases, setAllDiseases] = useState([]);
  const [diseaseFill, setDiseaseFill] = useState([]);

  const [scienceName, setScienceName] = useState();
  const [title, setTitle] = useState();
  const [commonName, setCommonName] = useState();
  const [description, setDescription] = useState();
  const [degreeDanger, setDegreeDanger] = useState();
  const [howToUse, setHowToUse] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [posion, setPosion] = useState();
  const [productUrl, setProductUrl] = useState();

  const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const token = localStorage.getItem("TOKEN");

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    images.length && console.log(images);
  }, [images]);

  useEffect(() => {
    setIsLoading(true);
    const fetchDisease = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/disease/get-diseases-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setAllDiseases(data.diseasesList);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchDisease();
  }, []);

  const onCheckChange = (e, t) => {
    if (e.target.checked) {
      setDiseaseFill([
        ...diseaseFill,
        {
          disease_id: t.disease_id,
        },
      ]);
    } else {
      setDiseaseFill(
        diseaseFill.filter((pItem) => pItem.disease_id !== t.disease_id)
      );
    }
  };

  const onTextChange = (e, t) => {
    let data = [...diseaseFill];
    console.log(data);
    const index = data.findIndex((d) => d.disease_id === t.disease_id);
    if (index > -1) {
      data[index].effectiveness = e.target.value;
    }
    setDiseaseFill(data);
  };

  const sendProduct = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);

    images.forEach((image) => {
      formData.append("gallery[]", image);
    });

    diseaseFill.forEach((item, i) => {
      formData.append(
        "diseasesId" + "[" + i + "]" + ["[disease_id]"],
        item.disease_id
      );
      formData.append(
        "diseasesId" + "[" + i + "]" + ["[effectiveness]"],
        item.effectiveness
      );
    });

    formData.append("product_type_id", productTypeId);

    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/product/add`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });

      if (data) {
        setIsLoading(false);
        navigate("/dashboard/products/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  console.log(diseaseFill);

  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/product/get-product-type`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProductType(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchProductType();
  }, [productTypeId]);

  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن محصول
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان محصول
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان محصول"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام علمی محصول
                  </label>
                  <input
                    value={scienceName}
                    type="text"
                    className="form-control"
                    name="science_name"
                    placeholder="نام علمی محصول"
                    onChange={(e) => setScienceName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام متداول
                  </label>
                  <input
                    value={commonName}
                    type="text"
                    className="form-control"
                    name="common_name"
                    placeholder="نام متداول"
                    onChange={(e) => setCommonName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    نوع محصول
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setProductId(e.target.value);
                    }}
                  >
                    <option className="text-right">
                      لطفا یک گزینه را انتخاب کنید
                    </option>
                    {productType.map((item) => {
                      return (
                        <>
                          <option
                            className="text-right"
                            value={item.product_type_id}
                          >
                            {item.title}
                          </option>
                        </>
                      );
                    })}{" "}
                  </select>
                </div>
                
                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                  دوره درمان با سم
                  </label>
                  <input
                    value={posion}
                    type="number"
                    className="form-control"
                    name="poison_period"
                    placeholder="دوره درمان با سم"
                    onChange={(e) => setPosion(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    آیا جزو محصولات ممنوعه است ؟
                  </label>
                  <select className="form-control" name="is_forbidden">
                    <option className="text-right">
                      لطفا یک گزینه را انتخاب کنید
                    </option>
                    <option className="text-right" value="yes">
                      بله
                    </option>
                    <option className="text-right" value="no">
                      خیر
                    </option>
                  </select>
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    درجه خطرناک بودن محصول
                  </label>
                  <input
                    value={degreeDanger}
                    type="number"
                    className="form-control"
                    name="degree_danger"
                    placeholder="بین 0 تا 100"
                    onChange={(e) => setDegreeDanger(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات محصول
                  </label>
                  <textarea
                    value={description}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="description"
                    placeholder="توضیحات محصول"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    نحوه استفاده از محصول
                  </label>
                  <textarea
                    value={howToUse}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="how_to_use"
                    placeholder="نحوه استفاده از محصول"
                    onChange={(e) => setHowToUse(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="image"
                    onChange={handleFileSelect}
                  />
                </div>

                {selectedFile ? (
                  <div className="imgFormResize mb-4">
                    <img src={selectedFile && selectedFile}></img>
                  </div>
                ) : null}
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو محصول
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  {images &&
                    images.map((img) => {
                      return (
                        <div className="row">
                          <div className="badge badge-dark mt-2 mr-4">
                            {img?.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                    value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    onChange={(e) => setVideoUrl(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                  لینک خرید محصول از سایت فروشگاه
                  </label>
                  <input
                    value={productUrl}
                    type="text"
                    className="form-control"
                    name="product_url"
                    placeholder="لینک خرید محصول از سایت فروشگاه"
                    onChange={(e) => setProductUrl(e.target.value)}
                    required
                  />
                </div>
              </div>

              <hr />

              {/* <div className="form-row mb-2">
              <label className="font-weight-bold" for="">
                 بیماری های مرتبط با این محصول
                </label>
              {allDiseases.map((t, index) => {
                return (
                    <div className="col-md-12" key={index}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDiseaseFill([
                                ...diseaseFill,
                                {
                                  disease_id: t.disease_id,
                                },
                              ]);
                            } else {
                              // remove from list
                              setDiseaseFill(
                                diseaseFill.filter(
                                  (pItem) => pItem.disease_id !== t.disease_id
                                )
                              );
                            }
                          }}
                          value={diseaseFill}
                        />

                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          {t.title}
                        </label>
                      </div>
                    </div>
                );
              })}
              </div> */}

              <div className="mb-2">
                <label className="font-weight-bold" for="">
                  بیماری های مرتبط با این محصول
                </label>
                {allDiseases.map((t, index) => {
                  return (
                    <div className="form-row mt-2">
                      <div className="col-md-4" key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => onCheckChange(e, t)}
                            value={
                              !!diseaseFill.find(
                                (p) => p.disease_id === t.disease_id
                              )
                            }
                          />

                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {t.title}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-1">
                        {!!diseaseFill.find(
                          (p) => p.disease_id === t.disease_id
                        ) && (
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onTextChange(e, t)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="form-row">
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={sendProduct}
                >
                  ثبت
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProduct;
