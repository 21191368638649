import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const AddDisease = () => {
  const [uniqueName, setUniqueName] = useState();
  const [title, setTitle] = useState();
  const [symptoms, setSymptoms] = useState();
  const [summary, setSummary] = useState();
  const [commonName, setCommonName] = useState();
  const [causes, setCauses] = useState();
  const [treatment, setTreatment] = useState();
  const [enName, setEnName] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [productUrl, setProductUrl] = useState();
  const [allPlants, setAllPlants] = useState([]);

  const [plantsFill, setPlantsFill] = useState([]);

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("TOKEN");

  const navigate = useNavigate();

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    images.length && console.log(images);
  }, [images]);


  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/plant/get-plants-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setAllPlants(data.plantsList);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPlants();
  }, []);

  console.log(plantsFill);

  const sendDisease = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(images);
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);
    images.forEach((image) => {
      formData.append("gallery[]", image);
    });

    plantsFill.forEach((plantFill) => {
      formData.append("plantsId[][plant_id]", plantFill.plant_id);
    });

    console.log(formData);

    try {
      const api = `${process.env.REACT_APP_URL}/website/disease/add`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setIsLoading(false);
        navigate("/dashboard/diseases/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  const onCheckChange = (e, t) => {
    if (e.target.checked) {
      setPlantsFill([
        ...plantsFill,
        {
          plant_id: t.plant_id,
        },
      ]);
    } else {
      setPlantsFill(
        plantsFill.filter((pItem) => pItem.plant_id !== t.plant_id)
      );
    }
  };

  const onTextChange = (e, t) => {
    let data = [...plantsFill];
    const index = data.findIndex((d) => d.plant_id === t.plant_id);
    if (index > -1) {
      data[index].effectiveness = e.target.value;
    }
    setPlantsFill(data);
  };

  console.log(plantsFill);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن بیماری
              </h2>
              <hr className="hrDisease" />

              <div className="form-row mt-4">
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام اختصاصی بیماری
                  </label>
                  <input
                    value={uniqueName}
                    type="text"
                    className="form-control"
                    name="unique_name"
                    placeholder="نام اختصاصی بیماری"
                    required
                    onChange={(e) => setUniqueName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان بیماری
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان بیماری"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام متداول
                  </label>
                  <input
                    value={commonName}
                    type="text"
                    className="form-control"
                    name="common_name"
                    placeholder="نام متداول"
                    required
                    onChange={(e) => setCommonName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="font-weight-bold" for="">
                    نام انگلیسی بیماری
                  </label>
                  <input
                    value={enName}
                    type="text"
                    className="form-control"
                    name="en_name"
                    placeholder="نام انگلیسی بیماری"
                    required
                    onChange={(e) => setEnName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    خلاصه
                  </label>
                  <textarea
                    value={summary}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="summary"
                    placeholder="خلاصه"
                    required
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                  علائم بیماری
                  </label>
                  <textarea
                    value={symptoms}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="symptoms"
                    placeholder="علائم بیماری"
                    required
                    onChange={(e) => setSymptoms(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    علت بیماری
                  </label>
                  <textarea
                    value={causes}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="causes"
                    placeholder="علت بیماری"
                    required
                    onChange={(e) => setCauses(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    نحوه درمان
                  </label>
                  <textarea
                    value={commonName}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="treatment"
                    placeholder="نحوه درمان"
                    required
                    onChange={(e) => setCommonName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="image"
                    onChange={handleFileSelect}
                  />
                </div>
                {selectedFile ? (
                  <div className="imgFormResize mb-4">
                    <img src={selectedFile && selectedFile}></img>
                  </div>
                ) : null}
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  {images &&
                    images.map((img) => {
                      return (
                        <div className="row">
                          <div className="badge badge-dark mt-2 mr-4">
                            {img?.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                    value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    required
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
              </div>


              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                  لینک خرید محصول
                  </label>
                  <input
                    value={productUrl}
                    type="text"
                    className="form-control"
                    name="recommended_product_url"
                    placeholder="لینک خرید محصول"
                    required
                    onChange={(e) => setProductUrl(e.target.value)}
                  />
                </div>
              </div>

              <hr />

              <div className="form-row mb-2">
                <label className="font-weight-bold" for="">
                  گیاهان مرتبط با این بیماری
                </label>
                {allPlants.map((t, index) => {
                  return (
                    <div className="col-md-12" key={index}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => onCheckChange(e, t)}
                          value={
                            !!plantsFill.find((p) => p.plant_id === t.plant_id)
                          }
                        />

                        <label className="form-check-label">{t.title}</label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={sendDisease}
              >
                ثبت
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddDisease;
