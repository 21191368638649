import React, { useEffect, useState } from "react";
import "./Form.css";
import { json, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useForm, useFieldArray } from "react-hook-form";
import Spinner from "../utils/Spinner";

const FaqTest = () => {
  const token = localStorage.getItem("TOKEN");
  const [isLoading, setIsLoading] = useState(false);
  const [haveFaq, setHaveFaq] = useState(null);
  const [tempData, setTempData] = useState([
    {
      question: "",
      answer: "",
    },
  ]);
  const { idPlant, idDisease, idProduct } = useParams();

  console.log(idPlant, idDisease, idProduct);

  const navigate = useNavigate();

  const [formFields, setFormFields] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  console.log(formFields);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleFormChange2 = (event, index) => {
    let data = [...tempData];
    data[index][event.target.name] = event.target.value;
    setTempData(data);
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(formFields);
  };

  const addFields = () => {
    let object = {
      question: "",
      answer: "",
    };

    setFormFields([...formFields, object]);
  };

  const addFields2 = () => {
    let object = {
      question: "",
      answer: "",
    };

    setTempData([...tempData, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    
    data.splice(index, 1);
    setFormFields(data);
  };

  const removeFields2 = (index) => {
    let data = [...tempData];
    
    data.splice(index, 1);
    setTempData(data);
  };

  console.log(formFields);
  console.log(tempData);

  async function getFaq(){
    setIsLoading(true);
    try {
      const api = `${process.env.REACT_APP_URL}/website/faq/get`;
      const { data } = await axios.post(
        api,
        {
          disease_id: idDisease,
          product_id: idProduct,
          plant_id: idPlant,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setHaveFaq(data)
      setTempData(data);
      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);

      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    getFaq()
  }, []);

  if (haveFaq) {
    console.log("post dari");
    console.log(haveFaq);
    // console.log(tempData[0].question);
  }

  // if(haveFaq == null){
  //   append({});
  // }

  const registerSubmit = async () => {
    setIsLoading(true);
    try {
      const api = `${process.env.REACT_APP_URL}/website/faq/add`;
      const { data } = await axios.post(
        api,
        {
          disease_id: idDisease,
          product_id: idProduct,
          plant_id: idPlant,
          faq: formFields,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if(data){
        if (idDisease) {
          navigate("/dashboard/diseases");
          toast.success(data.success, { autoClose: 15000 });
        } else if (idProduct){
          navigate("/dashboard/products");
          toast.success(data.success, { autoClose: 15000 });
        } else if (idPlant){
          navigate("/dashboard/plants");
          toast.success(data.success, { autoClose: 15000 });
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };
//  8 + 18 + 
  const numbers = [2, 3, 7 , 11 , 8];

function myFunc(total, num) {
  console.log("Num= "+num);
  console.log("Total= "+total);
  console.log("--------");
  return total + num + 3;
}
console.log("A = "+numbers.reduce(myFunc));

  const editSubmit = async () => {
    setIsLoading(true);
    try {
      const api = `${process.env.REACT_APP_URL}/website/faq/edit`;
      const { data } = await axios.post(
        api,
        {
          disease_id: idDisease,
          product_id: idProduct,
          plant_id: idPlant,
          faq: tempData,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if(data){
        if (idDisease) {
          navigate("/dashboard/diseases");
          toast.success(data.message, { autoClose: 15000 });
        } else if (idProduct){
          navigate("/dashboard/products");
          toast.success(data.message, { autoClose: 15000 });
        } else if (idPlant){
          navigate("/dashboard/plants");
          toast.success(data.message, { autoClose: 15000 });
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  return (
    <>
      <ToastContainer autoClose={8000} />
      {isLoading ? (
        <Spinner />
      ) : (
      <div className="bodyTable">
        <div className="container">
          {haveFaq ? (
            <>
              {tempData.map((form, index) => {
                return (
                  <div key={index + 1}>
                    {console.log(index)}
                    <input
                      className="form-control"
                      type="hidden"
                      value={idProduct}
                    />
                    <input type="hidden" value={idPlant} />
                    <input type="hidden" value={idDisease} />
                    <div className="form-row">
                      <div className="col-md-11 mb-3">
                        <label className="font-weight-bold" for="">
                          عنوان سوال
                        </label>
                        <input
                          className="form-control"
                          name="question"
                          placeholder="question"
                          onChange={(event) => handleFormChange2(event, index)}
                          value={tempData[index]?.question}
                          />
            
                      </div>

                      <div className="col-md-1 mt-4 iconSelf">
                        <p
                          className="btnRemove"
                          type="button"
                          onClick={() => removeFields2(index)}
                        >
                          ❌
                        </p>
                      </div>

                    </div>

                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label className="font-weight-bold" for="">
                          جواب سوال
                        </label>
                        <textarea
                          rows={5}
                          className="lineHeightAnswer form-control"
                          name="answer"
                          placeholder="answer"
                          onChange={(event) => handleFormChange2(event, index)}
                          value={tempData[index]?.answer}
                        />
                      </div>
                    </div>
                    <hr className="hrFAQ" />
                  </div>
                );
              })}

              <div className="form-row spaceBetweenButton">

              <div>
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={addFields2}
                  >
                    + اضافه کردن سوال جدید
                  </button>
                </div>

                <div>
                  <button
                    onClick={editSubmit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    ثبت
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>

              {formFields.map((form, index) => {
                return (
                  <div key={index + 1}>
                    <input
                      className="form-control"
                      type="hidden"
                      value={idProduct}
                    />
                    <input type="hidden" value={idPlant} />
                    <input type="hidden" value={idDisease} />
                    <div className="form-row">
                      <div className="col-md-11 mb-3">
                        <label className="font-weight-bold" for="">
                          عنوان سوال
                        </label>
                        <input
                          className="form-control"
                          name="question"
                          placeholder="question"
                          onChange={(event) => handleFormChange(event, index)}
                          value={form.question}
                        />
                      </div>

                      <div className="col-md-1 mt-4 iconSelf">
                        <p
                          className="btnRemove"
                          type="button"
                          onClick={() => removeFields(index)}
                        >
                          ❌
                        </p>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label className="font-weight-bold" for="">
                          جواب سوال
                        </label>
                        <textarea
                          rows={5}
                          className="lineHeightAnswer form-control"
                          name="answer"
                          placeholder="answer"
                          onChange={(event) => handleFormChange(event, index)}
                          value={form.answer}
                        />
                      </div>
                    </div>
                    <hr className="hrFAQ" />
                  </div>
                );
              })}

              <div className="form-row spaceBetweenButton">
                <div>
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={addFields}
                  >
                    + اضافه کردن سوال جدید
                  </button>
                </div>
                <div>
                  <button
                    onClick={registerSubmit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    ثبت
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
       )}
    </>
  );
};

export default FaqTest;
