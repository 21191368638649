import React, { useEffect, useState } from "react";
import "./Form.css";
import "./Diseases.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";
import { NavLink } from "react-router-dom";

const Diseases = () => {
  const token = localStorage.getItem("TOKEN");

  const [allDiseases, setAllDiseases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchAllMetrics = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/disease/get-diseases-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllDiseases(data.diseasesList);
        setIsLoading(false);
        console.log(data.diseasesList);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchAllMetrics();
  }, []);

  const deleteDisease = async (disease_id, e) => {
    e.preventDefault();
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/website/disease/delete/${disease_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const activePost = async (idPost) => {
    console.log(idPost);
    console.log("gogoli");
    try {
      const api =
        `${process.env.REACT_APP_URL}/website/disease/change-show-status`;
      console.log(api);
      const { data } = await axios.post(
        api,
        {
          disease_id: idPost,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(data);
      toast.success(data.message, { autoClose: 15000 });
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };
  
  const handleCheck = (setStatus) => {
    if(setStatus == 'publish'){
      return true
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <h3 className="mb-4">بیماری ها</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="table-wrap">
                <table className="table">
                  <thead className="thead-primary">
                    <tr>
                      <th>عنوان بیماری</th>
                      <th>نام اختصاصی بیماری</th>
                      <th>وضعیت</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDiseases.map((item) => {
                      return (
                        <tr>
                          <td>
                            {" "}
                            <NavLink
                              key={item.disease_id}
                              to={`/dashboard/disease/${item.disease_id}`}
                            >
                              {item.title}
                            </NavLink>
                          </td>
                          <td>{item.unique_name}</td>
                          <td>
                            <input
                              className="checkHeight"
                              defaultChecked={handleCheck(item.show_status)}
                              type="checkbox"
                              value={item.show_status}
                              id="defaultCheck1"
                              onChange={() => activePost(item.disease_id)}
                            />
                          </td>

                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.disease_id}
                              to={`/dashboard/faq-disease/${item.disease_id}`}
                            >
                              افزودن FAQ
                            </NavLink>
                          </td>
                          <td className="text-left">
                            {" "}
                            <NavLink
                              key={item.disease_id}
                              to={`/dashboard/disease/${item.disease_id}`}
                            >
                              <img
                                className="imgS"
                                src={require("../../assets/images/explain/arrow-left.png")}
                                alt="logo"
                              />
                            </NavLink>
                          </td>

                          <td className="text-left">
                            <img
                              onClick={(e) => {
                                deleteDisease(item.disease_id, e);
                              }}
                              className="imgS"
                              src={require("../../assets/images/explain/delete.png")}
                              alt="logo"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Diseases;
