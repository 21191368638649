import React, { useEffect, useState } from "react";
import axios from "axios";

const DashboardAll = () => {
  const token = localStorage.getItem("TOKEN");

  const [allCount, setAllCount] = useState({});

  useEffect(() => {
    const fetchAllCount = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/dashboard/count`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setAllCount(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchAllCount();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <div className="card-body">
              <h4 className="font-weight-bold mb-3 text-center"> تعداد بیماری اضافه شده</h4>
              <h2 className="font-weight-bold  text-center">{allCount.disease}</h2>
            </div>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-info card-img-holder text-white">
            <div className="card-body">
            <h4 className="font-weight-bold mb-3 text-center"> تعداد محصولات اضافه شده</h4>
              <h2 className="font-weight-bold  text-center">{allCount.product}</h2>
            
            </div>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <div className="card-body">
            <h4 className="font-weight-bold mb-3 text-center"> تعداد گل و گیاه اضافه شده</h4>
              <h2 className="font-weight-bold  text-center">{allCount.plant}</h2>
            
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">آخرین تراکنش</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th> کاربران </th>
                      <th> سمت </th>
                      <th> وضعیت </th>
                      <th> تاریخ </th>
                      <th> آیدی تراکنش </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face1.jpg")}
                          className="mr-2"
                          alt="face"
                        />{" "}
                        ممد دوتال{" "}
                      </td>
                      <td> گیاه اوکالیپتوس </td>
                      <td>
                        <label className="badge badge-gradient-success">
                          پرداخت شده
                        </label>
                      </td>
                      <td> 27 آذر 1401	 </td>
                      <td> H545VRTFTRTY </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face1.jpg")}
                          className="mr-2"
                          alt="face"
                        />{" "}
                        ممد دوتال{" "}
                      </td>
                      <td> گیاه اوکالیپتوس </td>
                      <td>
                        <label className="badge badge-gradient-warning">
                          در انتظار پرداخت
                        </label>
                      </td>
                      <td> 27 آذر 1401	 </td>
                      <td> H545VRTFTRTY </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face1.jpg")}
                          className="mr-2"
                          alt="face"
                        />{" "}
                        ممد دوتال{" "}
                      </td>
                      <td> گیاه اوکالیپتوس </td>
                      <td>
                        <label className="badge badge-gradient-danger">
                          لغو شده
                        </label>
                      </td>
                      <td> 27 آذر 1401	 </td>
                      <td> H545VRTFTRTY </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAll;
