import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const Product = () => {
  const { id } = useParams();
  const URL = "https://golet.jargeh.com/golet/";
  const token = localStorage.getItem("TOKEN");

  const [allProduct, setAllProduct] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState();
  const [scienceName, setScienceName] = useState();
  const [commonName, setCommonName] = useState();
  const [image, setImage] = useState();
  const [howToUse, setHowToUse] = useState();
  const [isForbidden, setIsForbidden] = useState();
  const [degreeDanger, setDegreeDanger] = useState();
  const [description, setDescription] = useState();
  const [video, setVideo] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [posion, setPosion] = useState();
  const [productUrl, setProductUrl] = useState();

  const [allDiseases, setAllDiseases] = useState([]);
  const [diseaseFill, setDiseaseFill] = useState([]);

  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);

  const navigate = useNavigate();

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    images.length && console.log(images);
  }, [images]);

  useEffect(() => {
    setIsLoading(true);
    const fetchDisease = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/disease/get-diseases-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllDiseases(data.diseasesList);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchDisease();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchProduct = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/product/get-product/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllProduct(data.productInfo);
        setEditImages(data.gallery);
        setIsLoading(false);
        console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchProduct();
  }, []);

  const onCheckChange = (e, t) => {
    if (e.target.checked) {
      setDiseaseFill([
        ...diseaseFill,
        {
          disease_id: t.disease_id,
        },
      ]);
    } else {
      setDiseaseFill(
        diseaseFill.filter((pItem) => pItem.disease_id !== t.disease_id)
      );
    }
  };

  const onTextChange = (e, t) => {
    let data = [...diseaseFill];
    console.log(data);
    const index = data.findIndex((d) => d.disease_id === t.disease_id);
    if (index > -1) {
      data[index].effectiveness = e.target.value;
    }
    setDiseaseFill(data);
  };

  useEffect(() => {
    setTitle(allProduct.title);
    setScienceName(allProduct.science_name);
    setCommonName(allProduct.common_name);
    setHowToUse(allProduct.how_to_use);
    setDegreeDanger(allProduct.degree_danger);
    setImage(allProduct.image);
    setDescription(allProduct.description);
    setVideoUrl(allProduct.video_url);
    setVideo(allProduct.video);
    setPosion(allProduct.poison_period);
    setProductUrl(allProduct.product_url);
  }, [allProduct]);

  const editProduct = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);
    formData.append("product_id", id);

    images.forEach((image) => {
      formData.append("gallery[]", image);
    });

    diseaseFill.forEach((item, i) => {
      formData.append(
        "diseasesId" + "[" + i + "]" + ["[disease_id]"],
        item.disease_id
      );
      formData.append(
        "diseasesId" + "[" + i + "]" + ["[effectiveness]"],
        item.effectiveness
      );
    });

    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/product/edit`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        navigate("/dashboard/products/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  const deleteImage = async (imgId, e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/website/product/delete-image/${id}/${imgId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditImages((images) => images.filter((img) => img.id !== imgId));
      toast.success(data.message, { autoClose: 15000 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                ویرایش محصول {allProduct.title}
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان محصول
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان محصول"
                    required
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام علمی محصول
                  </label>
                  <input
                    value={scienceName}
                    type="text"
                    className="form-control"
                    name="science_name"
                    placeholder="نام علمی محصول"
                    required
                    onChange={(e) => setScienceName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام متداول
                  </label>
                  <input
                    value={commonName}
                    type="text"
                    className="form-control"
                    name="common_name"
                    placeholder="نام متداول"
                    required
                    onChange={(e) => setCommonName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    نوع محصول
                  </label>
                  <select className="form-control" name="product_type_id">
                    <option className="text-right">
                      لطفا یک گزینه را انتخاب کنید
                    </option>

                    <option className="text-right" value="1">
                      کود
                    </option>
                    <option className="text-right" value="2">
                      سم
                    </option>
                    <option className="text-right" value="3">
                      گلدان
                    </option>
                  </select>
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                  دوره درمان با سم
                  </label>
                  <input
                    value={posion}
                    type="number"
                    className="form-control"
                    name="poison_period"
                    placeholder="دوره درمان با سم"
                    onChange={(e) => setPosion(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    آیا جزو محصولات ممنوعه است ؟
                  </label>
                  <select className="form-control" name="is_forbidden">
                    <option className="text-right">
                      لطفا یک گزینه را انتخاب کنید
                    </option>
                    <option className="text-right" value="yes">
                      بله
                    </option>
                    <option className="text-right" value="no">
                      خیر
                    </option>
                  </select>
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    درجه خطرناک بودن محصول
                  </label>
                  <input
                    value={degreeDanger}
                    type="number"
                    className="form-control"
                    name="degree_danger"
                    placeholder="بین 0 تا 100"
                    required
                    onChange={(e) => setDegreeDanger(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات محصول
                  </label>
                  <textarea
                    value={description}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="description"
                    placeholder="توضیحات محصول"
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    نحوه استفاده از محصول
                  </label>
                  <textarea
                    rows={5}
                    value={howToUse}
                    type="text"
                    className="form-control"
                    name="how_to_use"
                    placeholder="نحوه استفاده از محصول"
                    required
                    onChange={(e) => setHowToUse(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input className="inputWidth100" type="file" name="image" />
                  <div className="imgFormResize mt-3">
                    <img src={URL + allProduct.image} />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو محصول
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  <div className="row">
                    {editImages.map((img) => {
                      return (
                        <div>
                          <div className="imgFormResize text-center mb-2 mt-3">
                            <img src={URL + img.address} />
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              deleteImage(img.id, e);
                            }}
                          >
                            حذف
                          </button>
                        </div>
                      );
                    })}
                  </div>

                  <div className="row">
                    {images &&
                      images.map((img) => {
                        return <p className="mr-4">{img?.name}</p>;
                      })}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                    value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    required
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                  لینک خرید محصول از سایت فروشگاه
                  </label>
                  <input
                    value={productUrl}
                    type="text"
                    className="form-control"
                    name="product_url"
                    placeholder="لینک خرید محصول از سایت فروشگاه"
                    onChange={(e) => setProductUrl(e.target.value)}
                    required
                  />
                </div>
              </div>

              <hr />

              <div className="mb-2">
                <label className="font-weight-bold" for="">
                  بیماری های مرتبط با این محصول
                </label>
                {allDiseases.map((t, index) => {
                  return (
                    <div className="form-row mt-2">
                      <div className="col-md-4" key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => onCheckChange(e, t)}
                            value={
                              !!diseaseFill.find(
                                (p) => p.disease_id === t.disease_id
                              )
                            }
                          />

                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {t.title}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-1">
                        {!!diseaseFill.find(
                          (p) => p.disease_id === t.disease_id
                        ) && (
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onTextChange(e, t)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="form-row">
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={editProduct}
                >
                  ویرایش
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
