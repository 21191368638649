import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const AddPlant = () => {
  const [productType, setProductType] = useState([]);
  const [productTypeId, setProductId] = useState();
  const [allDiseases, setAllDiseases] = useState([]);
  const [diseaseFill, setDiseaseFill] = useState([]);

  const [title, setTitle] = useState();
  const [uniqueName, setUniqueName] = useState();
  const [scienceName, setScienceName] = useState();
  const [fertilizer, setFertilizer] = useState();
  const [nativeRegion, setNativeRegion] = useState();
  const [family, setFamily] = useState();
  const [temperature, setTemperature] = useState();
  const [lighting, setLighting] = useState();
  const [repotTime, setRepotTime] = useState();
  const [toxic, setToxic] = useState();
  const [waterPeriod, setWaterPeriod] = useState();
  const [briefDescription, setBriefDescription] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState();

  const navigate = useNavigate();

  const token = localStorage.getItem("TOKEN");
  const [isLoading, setIsLoading] = useState(false);

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    images.length && console.log(images);
  }, [images]);

  const sendPlant = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);

    images.forEach((image) => {
      formData.append("gallery_image[]", image);
    });

    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/plant/add`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });

      if (data) {
        navigate("/dashboard/plants/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن گل و گیاه
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان گیاه
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان گیاه"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام اختصاصی گیاه
                  </label>
                  <input
                    value={uniqueName}
                    type="text"
                    className="form-control"
                    name="unique_name"
                    placeholder="نام اختصاصی گیاه"
                    onChange={(e) => setUniqueName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام علمی
                  </label>
                  <input
                  value={scienceName}
                    type="text"
                    className="form-control"
                    name="science_name"
                    placeholder="نام علمی"
                    onChange={(e) => setScienceName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    کود دهی
                  </label>
                  <input
                  value={fertilizer}
                    type="number"
                    className="form-control"
                    name="fertilizer"
                    placeholder="کود دهی"
                    onChange={(e) => setFertilizer(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    بومی کدام منطقه است؟
                  </label>
                  <input
                  value={nativeRegion}
                    type="text"
                    className="form-control"
                    name="native_region"
                    placeholder="بومی کدام منطقه است؟"
                    onChange={(e) => setNativeRegion(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    از کدام خانوداه است؟
                  </label>
                  <input
                  value={family}
                    type="text"
                    className="form-control"
                    name="family"
                    placeholder="از کدام خانوداه است؟"
                    onChange={(e) => setFamily(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
              <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    دوره آبیاری گیاه
                  </label>
                  <input
                  value={waterPeriod}
                    type="number"
                    className="form-control"
                    name="water_period"
                    placeholder="دوره آبیاری گیاه"
                    onChange={(e) => setWaterPeriod(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label className="font-weight-bold" for="">
                    دمای گیاه
                  </label>
                  <input
                  value={temperature}
                    type="text"
                    className="form-control"
                    name="temperature"
                    placeholder="دمای گیاه"
                    onChange={(e) => setTemperature(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    نور گیاه
                  </label>
                  <input
                  value={lighting}
                    type="number"
                    className="form-control"
                    name="lighting"
                    placeholder="نور گیاه"
                    onChange={(e) => setLighting(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    عوض کردن گلدان
                  </label>
                  <input
                  value={repotTime}
                    type="number"
                    className="form-control"
                    name="repot_time"
                    placeholder="عوض کردن گلدان"
                    onChange={(e) => setRepotTime(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    سمی بودن
                  </label>
                  <input
                  value={toxic}
                    type="text"
                    className="form-control"
                    name="toxic"
                    placeholder="سمی بودن"
                    onChange={(e) => setToxic(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    محل نگه داری گیاه
                  </label>
                  <select className="form-control" name="keeping_place">
                    <option className="text-right" value="outdoor">
                      خارج از خانه
                    </option>
                    <option className="text-right" value="indoor">
                      داخل خانه
                    </option>
                    <option className="text-right" value="both">
                      هر دو
                    </option>
                  </select>
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    درجه سختی گیاه
                  </label>
                  <select className="form-control" name="hardship_level">
                    <option className="text-right" value="low">
                      کم
                    </option>
                    <option className="text-right" value="medium">
                      متوسط
                    </option>
                    <option className="text-right" value="high">
                      زیاد
                    </option>
                  </select>
                </div>
              </div>


              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات خلاصه
                  </label>
                  <textarea
                  value={briefDescription}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="brief_description"
                    placeholder="توضیحات خلاصه"
                    onChange={(e) => setBriefDescription(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="image"
                    onChange={handleFileSelect}
                  />
                </div>

                {selectedFile ? (
                  <div className="imgFormResize mb-4">
                    <img src={selectedFile && selectedFile}></img>
                  </div>
                ) : null}
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  <div className="row">
                    {images &&
                      images.map((img) => {
                        return (
                          <div className="badge badge-dark mt-2 mr-4">
                            {img?.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                  value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    onChange={(e) => setVideoUrl(e.target.value)}
                    required
                  />
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={sendPlant}
              >
                ثبت
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPlant;
