import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Spinner from "../utils/Spinner";

const Plant = () => {
  const URL = "https://golet.jargeh.com/golet/";
  const token = localStorage.getItem("TOKEN");

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState();
  const [uniqueName, setUniqueName] = useState("");
  const [scienceName, setScienceName] = useState("");
  const [fertilizer, setFertilizer] = useState("");
  const [nativeRegion, setNativeRegion] = useState("");
  const [family, setFamily] = useState("");
  const [temperature, setTemperature] = useState("");
  const [lighting, setLighting] = useState("");
  const [repotTime, setRepotTime] = useState("");
  const [toxic, setToxic] = useState("");
  const [waterPeriod, setWaterPeriod] = useState();
  const [prune, setPrune] = useState();
  const [propagation, setPropagation] = useState();
  const [briefDescription, setBriefDescription] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const [allPlant, setAllPlant] = useState([]);

  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);

  const imageHandler = (e) => {
    setImages([...images, e.target.files[0]]);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchPlant = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/plant/get-plant/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(data);
        setAllPlant(data.plantInfo);
        setEditImages(data.gallery);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPlant();
  }, []);

  console.log(editImages);

  useEffect(() => {
    setTitle(allPlant.title);
    setUniqueName(allPlant.unique_name);
    setScienceName(allPlant.science_name);
    setFertilizer(allPlant.fertilizer);
    setNativeRegion(allPlant.native_region);
    setFamily(allPlant.family);
    setTemperature(allPlant.temperature);
    setLighting(allPlant.lighting);
    setRepotTime(allPlant.repot_time);
    setToxic(allPlant.toxic);
    setWaterPeriod(allPlant.water_period);
    setBriefDescription(allPlant.brief_description);
    setVideoUrl(allPlant.video_url);
  }, [allPlant]);

  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  const editPlant = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);
    formData.append("plant_id", id);

    images.forEach((image) => {
      formData.append("gallery[]", image);
    });

    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/plant/edit`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        navigate("/dashboard/plants/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
    }
  };

  const deleteImage = async (imgId, e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/website/plant/delete-image/${id}/${imgId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditImages((images) => images.filter((img) => img.id !== imgId));
      toast.success(data.message, { autoClose: 15000 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن گل و گیاه
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان گیاه
                  </label>
                  <input
                    value={title}
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="عنوان گیاه"
                    required
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام اختصاصی گیاه
                  </label>
                  <input
                    value={uniqueName}
                    type="text"
                    className="form-control"
                    name="unique_name"
                    placeholder="نام اختصاصی گیاه"
                    required
                    onChange={(e) => setUniqueName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    نام علمی
                  </label>
                  <input
                    value={scienceName}
                    type="text"
                    className="form-control"
                    name="science_name"
                    placeholder="نام علمی"
                    required
                    onChange={(e) => setScienceName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    کود دهی
                  </label>
                  <input
                    value={fertilizer}
                    type="number"
                    className="form-control"
                    name="fertilizer"
                    placeholder="کود دهی"
                    required
                    onChange={(e) => setFertilizer(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    بومی کدام منطقه است؟
                  </label>
                  <input
                    value={nativeRegion}
                    type="text"
                    className="form-control"
                    name="native_region"
                    placeholder="بومی کدام منطقه است؟"
                    required
                    onChange={(e) => setNativeRegion(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    از کدام خانوداه است؟
                  </label>
                  <input
                    value={family}
                    type="text"
                    className="form-control"
                    name="family"
                    placeholder="از کدام خانوداه است؟"
                    required
                    onChange={(e) => setFamily(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    دوره آبیاری گیاه
                  </label>
                  <input
                    value={waterPeriod}
                    type="number"
                    className="form-control"
                    name="water_period"
                    placeholder="دوره آبیاری گیاه"
                    onChange={(e) => setWaterPeriod(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label className="font-weight-bold" for="">
                    دمای گیاه
                  </label>
                  <input
                    value={temperature}
                    type="text"
                    className="form-control"
                    name="temperature"
                    placeholder="دمای گیاه"
                    required
                    onChange={(e) => setTemperature(e.target.value)}
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    نور گیاه
                  </label>
                  <input
                    value={lighting}
                    type="number"
                    className="form-control"
                    name="lighting"
                    placeholder="نور گیاه"
                    required
                    onChange={(e) => setLighting(e.target.value)}
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label className="font-weight-bold" for="">
                    عوض کردن گلدان
                  </label>
                  <input
                    value={repotTime}
                    type="number"
                    className="form-control"
                    name="repot_time"
                    placeholder="عوض کردن گلدان"
                    required
                    onChange={(e) => setRepotTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    سمی بودن
                  </label>
                  <input
                    value={toxic}
                    type="text"
                    className="form-control"
                    name="toxic"
                    placeholder="سمی بودن"
                    required
                    onChange={(e) => setToxic(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    محل نگه داری گیاه
                  </label>
                  <select className="form-control" name="keeping_place">
                    <option className="text-right" value="outdoor">
                      خارج از خانه
                    </option>
                    <option className="text-right" value="indoor">
                      داخل خانه
                    </option>
                    <option className="text-right" value="both">
                      هر دو
                    </option>
                  </select>
                </div>

                <div className="col-md-4 mb-3">
                  <label className="font-weight-bold" for="">
                    درجه سختی گیاه
                  </label>
                  <select className="form-control" name="hardship_level">
                    <option className="text-right" value="low">
                      کم
                    </option>
                    <option className="text-right" value="medium">
                      متوسط
                    </option>
                    <option className="text-right" value="high">
                      زیاد
                    </option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات خلاصه
                  </label>
                  <textarea
                    value={briefDescription}
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="brief_description"
                    placeholder="توضیحات خلاصه"
                    required
                    onChange={(e) => setBriefDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input
                    className="inputWidth100 mt-3"
                    type="file"
                    name="image"
                    onChange={handleFileSelect}
                  />
                  <div className="imgFormResize mt-3">
                    <img src={URL + allPlant.image} />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو
                  </label>
                  <input type="file" name="video" className="inputWidth100" />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    گالری تصاویر
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="gallery"
                    onChange={imageHandler}
                    required
                    multiple
                  />
                  <div className="row">
                    {editImages.map((img) => {
                      return (
                        <div>
                          <div className="imgFormResize text-center mb-2 mt-3">
                            <img src={URL + img.address} />
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              deleteImage(img.id, e);
                            }}
                          >
                            حذف
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    {images &&
                      images.map((img) => {
                        return <p className="mr-4">{img?.name}</p>;
                      })}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    آدرس ویدیو
                  </label>
                  <input
                    value={videoUrl}
                    type="text"
                    className="form-control"
                    name="video_url"
                    placeholder="آدرس ویدیو"
                    required
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={editPlant}
              >
                ثبت
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Plant;
