import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const AddInfoPlant = () => {
  const { id } = useParams();

  const token = localStorage.getItem("TOKEN");

  const navigate = useNavigate();

  const [infoPlant, setInfoPlant] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [productsFill, setProductsFill] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/product/get-product-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setAllProducts(data.productList);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchProducts();
  }, []);

  const onCheckChange = (e, t) => {
    if (e.target.checked) {
      setProductsFill([
        ...productsFill,
        {
          product_id: t.product_id,
        },
      ]);
    } else {
      setProductsFill(
        productsFill.filter((pItem) => pItem.product_id !== t.product_id)
      );
    }
  };

  const onTextChange = (e, t) => {
    let data = [...productsFill];
    const index = data.findIndex((d) => d.product_id === t.product_id);
    if (index > -1) {
      data[index].effectiveness = e.target.value;
    }
    setProductsFill(data);
  };

  useEffect(() => {
    const fetchPlant = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/website/plant-info/get-plant-info/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        setInfoPlant(data.plantInfo);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPlant();
  }, []);

  console.log(infoPlant);

  const sendPlant = async (e) => {
    e.preventDefault();
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);

    formData.append("plant_id", id);

    productsFill.forEach((item, i) => {
      formData.append(
        "productsId" + "[" + i + "]" + ["[product_id]"],
        item.product_id
      );
      formData.append(
        "productsId" + "[" + i + "]" + ["[effectiveness]"],
        item.effectiveness
      );
    });

    console.log(formData);

    try {
      const api = `${process.env.REACT_APP_URL}/website/plant-info/add`;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        navigate("/dashboard/plants/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  const editPlant = async (e) => {
    e.preventDefault();
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);

    formData.append("plant_id", id);
    productsFill.forEach((item, i) => {
      formData.append(
        "productsId" + "[" + i + "]" + ["[product_id]"],
        item.product_id
      );
      formData.append(
        "productsId" + "[" + i + "]" + ["[effectiveness]"],
        item.effectiveness
      );
    });
    console.log(formData);
    try {
      const api = `${process.env.REACT_APP_URL}/website/plant-info/edit`;;

      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        navigate("/dashboard/plants/");
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      console.log(e.response.data.error);
    }
  };

  return (
    <>
      <div className="container">
        <ToastContainer autoClose={8000} />
        <div className="row">
          {infoPlant != undefined ? (
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                y ویرایش اطلاعات کلی گل و گیاه
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات آبیاری
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="water_description"
                    placeholder="توضیحات آبیاری"
                    required
                    value={infoPlant.water_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات دما
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="temperature_description"
                    placeholder="توضیحات دما"
                    required
                    value={infoPlant.temperature_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات رطوبت
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="humidity_description"
                    placeholder="توضیحات رطوبت"
                    required
                    value={infoPlant.humidity_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات خاک
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="soil_description"
                    placeholder="توضیحات خاک"
                    required
                    value={infoPlant.soil_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات نور
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="light_description"
                    placeholder="توضیحات نور"
                    required
                    value={infoPlant.light_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات کود دهی
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="fertilizer_description"
                    placeholder="توضیحات کود دهی"
                    required
                    value={infoPlant.fertilizer_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات هرس کردن
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="prune_description"
                    placeholder="توضیحات هرس کردن"
                    required
                    value={infoPlant.prune_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات تعویض گلدان
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="repot_description"
                    placeholder="توضیحات تعویض گلدان"
                    required
                    value={infoPlant.repot_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات نام های متداول
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="common_name"
                    placeholder="توضیحات نام های متداول"
                    required
                    value={infoPlant.common_name}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات گیاه نماد چیست
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="symbol_description"
                    placeholder="توضیحات گیاه نماد چیست"
                    required
                    value={infoPlant.symbol_description}
                    onChange={(e) => setInfoPlant(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row mb-2">
                <label className="font-weight-bold" for="">
                  محصولات مرتبط با این گیاه
                </label>
                {allProducts.map((t, index) => {
                  return (
                    <>
                      <div className="col-md-10" key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => onCheckChange(e, t)}
                            value={
                              !!productsFill.find(
                                (p) => p.product_id === t.product_id
                              )
                            }
                          />

                          <label className="form-check-label">{t.title}</label>
                        </div>
                      </div>

                      <div className="col-md-1">
                        {!!productsFill.find(
                          (p) => p.product_id === t.product_id
                        ) && (
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onTextChange(e, t)}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="form-row">
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={editPlant}
                >
                  ویرایش
                </button>
              </div>
            </form>
          ) : (
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن توضیحات و اطلاعات کلی گل و گیاه
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات آبیاری
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="water_description"
                    placeholder="توضیحات آبیاری"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات نور
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="light_description"
                    placeholder="توضیحات نور"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات دما
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="temperature_description"
                    placeholder="توضیحات دما"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات رطوبت
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="humidity_description"
                    placeholder="توضیحات رطوبت"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات خاک
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="soil_description"
                    placeholder="توضیحات خاک"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات کود دهی
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="fertilizer_description"
                    placeholder="توضیحات کود دهی"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات هرس کردن
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="prune_description"
                    placeholder="توضیحات هرس کردن"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات تعویض گلدان
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="repot_description"
                    placeholder="توضیحات تعویض گلدان"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات نام های متداول
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="common_name"
                    placeholder="توضیحات نام های متداول"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات گیاه نماد چیست
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="symbol_description"
                    placeholder="توضیحات گیاه نماد چیست"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    بیماری های متداول
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="common_disease"
                    placeholder="بیماری های متداول"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات گیاه نماد چیست
                  </label>
                  <textarea
                    rows={5}
                    type="text"
                    className="lineHeightAnswer form-control"
                    name="symbol_description"
                    placeholder="توضیحات گیاه نماد چیست"
                    required
                  />
                </div>
              </div>

              <div className="form-row mb-2">
                <label className="font-weight-bold" for="">
                  محصولات مرتبط با این گیاه
                </label>
                {allProducts.map((t, index) => {
                  return (
                    <>
                      <div className="col-md-12" key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => onCheckChange(e, t)}
                            value={
                              !!productsFill.find(
                                (p) => p.product_id === t.product_id
                              )
                            }
                          />

                          <label className="form-check-label">{t.title}</label>
                        </div>
                      </div>

                      <div className="col-md-1">
                        {!!productsFill.find(
                          (p) => p.product_id === t.product_id
                        ) && (
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onTextChange(e, t)}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="form-row">
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={sendPlant}
                >
                  ثبت
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AddInfoPlant;
