import React, { useEffect, useState } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../utils/Spinner";

import axios from "axios";

const AddPropagation = () => {
  const { id } = useParams();

  const [mainTitle, setMainTitle] = useState();
  const [mainDescription, setMainDescription] = useState();


  const token = localStorage.getItem("TOKEN");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [formFields, setFormFields] = useState([
    {
      title: "",
      description: "",
      second_description: "",
      step_number: +1,
      image: null,
    },
  ]);
  const [selectedFile, setSelectedFile] = useState();

  const handleFileSelect = (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    if (event.target.name == "image") {
      data[index][event.target.name] = event.target.files[0];
    }
    setFormFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(formFields);
  };

  const addFields = () => {
    let object = {
      title: "",
      description: "",
      second_description: "",
      step_number: 1,
      image: null,
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const sendPlant = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = document.getElementById("signUpForm");
    const formData = new FormData(form);

    formData.append("plant_id", id);

    console.log(formFields);

    const a = "steps";
    formFields.forEach((item, i) => {
      formData.append(a + "[" + i + "]" + ["[title]"], item["title"]);
      formData.append(
        a + "[" + i + "]" + ["[description]"],
        item["description"]
      );
      formData.append(
        a + "[" + i + "]" + ["[second_description]"],
        item["second_description"]
      );
      formData.append(a + "[" + i + "]" + ["[step_number]"], i);
      formData.append(a + "[" + i + "]" + ["[image]"], item["image"]);
    });

    try {
      const api = `${process.env.REACT_APP_URL}/website/propagation/add`;

      console.log(formData);
      const { data } = await axios.post(api, formData, {
        headers: {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        navigate("/dashboard/plants/");
        console.log(data);
        toast.success(data.success, { autoClose: 15000 });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.error, { autoClose: 15000 });
      setIsLoading(false)
      console.log(e.response.data.error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <form id="signUpForm" className="md-12">
              <h2 className="text-center mb-4 font-weight-bold">
                افزودن اطلاعات کلی تکثیر
              </h2>
              <hr className="hrProduct" />

              <div className="form-row mt-4">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    عنوان تکثیر گیاه
                  </label>
                  <input
                  value={mainTitle}
                    type="text"
                    className="form-control"
                    name="main_title"
                    placeholder="عنوان تکثیر گیاه"
                    onChange={(e) => setMainTitle(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    توضیحات اصلی نحوه تکثیر
                  </label>
                  <textarea
                  value={mainDescription}
                    rows={5}
                    type="text"
                    className="form-control"
                    name="main_description"
                    placeholder="توضیحات اصلی نحوه تکثیر"
                    onChange={(e) => setMainDescription(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    تصویر شاخص
                  </label>
                  <input
                    className="inputWidth100"
                    type="file"
                    name="image"
                    onChange={handleFileSelect}
                  />
                </div>
                {selectedFile ? (
                  <div className="imgFormResize mb-4">
                    <img src={selectedFile && selectedFile}></img>
                  </div>
                ) : null}
              </div>

              <hr />

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label className="font-weight-bold" for="">
                    ویدیو محصول
                  </label>
                  <input className="inputWidth100" type="file" name="video" />
                </div>
              </div>

              <hr className="hrProduct" />

              <div>
                {formFields.map((form, index) => {
                  return (
                    <div key={index + 1}>
                      <h2 className="font-weight-bold text-center">
                        مرحله {index + 1}
                      </h2>
                      <input
                        type="hidden"
                        name="step_number"
                        className="form-control"
                        onChange={(event) => handleFormChange(event, index)}
                        value={form.step_number}
                      />
                      <div className="form-row mt-4">
                        <div className="col-md-6">
                          <label className="font-weight-bold" for="">
                            عنوان
                          </label>
                          <input
                            name="title"
                            placeholder="عنوان"
                            className="form-control"
                            onChange={(event) => handleFormChange(event, index)}
                            value={form.title}
                          />
                        </div>
                        <div className="col-md-5">
                          <label className="font-weight-bold" for="">
                            تصویر
                          </label>
                          <input
                            type="file"
                            name="image"
                            className="form-control"
                            onChange={(event) => handleFormChange(event, index)}
                          />
                        </div>

                        <div className="col-md-1 mt-4 iconSelf">
                          <p
                            className="btnRemove"
                            type="button"
                            onClick={() => removeFields(index)}
                          >
                            ❌
                          </p>
                        </div>
                      </div>

                      <div className="form-row mt-4">
                        <div className="col-md-12">
                          <label className="font-weight-bold" for="">
                            توضیحات
                          </label>
                          <textarea
                            rows={6}
                            name="description"
                            className="form-control"
                            placeholder="توضیحات"
                            onChange={(event) => handleFormChange(event, index)}
                            value={form.description}
                          />
                        </div>
                      </div>

                      <div className="form-row mt-4">
                        <div className="col-md-12">
                          <label className="font-weight-bold" for="">
                            توضیحات دوم
                          </label>
                          <textarea
                            rows={6}
                            name="second_description"
                            className="form-control mb-3"
                            placeholder="توضیحات دوم"
                            onChange={(event) => handleFormChange(event, index)}
                            value={form.second_description}
                          />
                        </div>
                      </div>
                      <hr className="hrDisease" />
                    </div>
                  );
                })}
              </div>

              <div className="form-row spaceBetweenButton">
                <div>
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={addFields}
                  >
                    + اضافه کردن سوال جدید
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-primary mt-4"
                    onClick={sendPlant}
                  >
                    ثبت
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPropagation;
