import React, { useEffect } from "react";
import Navbar from "../shared/Navbar";
import Sidebar from "../shared/Sidebar";
import SettingsPanel from "../shared/SettingsPanel";
import Footer from "../shared/Footer";
import DashboardAll from "../../components/Dashboard/DashboardAll";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import AddDisease from "../../components/Dashboard/AddDisease";
import Diseases from "../../components/Dashboard/Diseases";
import Disease from "../../components/Dashboard/Disease";
import AddProduct from "../../components/Dashboard/AddProduct";
import Products from "../../components/Dashboard/Products";
import Product from "../../components/Dashboard/Product";
import AddPlant from "../../components/Dashboard/AddPlant";
import Plants from "../../components/Dashboard/Plants";
import Plant from "../../components/Dashboard/Plant";
import AddInfoPlant from "../../components/Dashboard/AddInfoPlant";
import Faq from "../../components/Dashboard/Faq";
import AddPropagation from "../../components/Dashboard/AddPropagation";
import FaqTest from "../../components/Dashboard/FaqTest";
  const Dashboard = () => {
    const navigate = useNavigate();
    useEffect(()=>{
      if(!localStorage.getItem("TOKEN")){
        navigate('/login')
      }
    },[])
    return (
      <>
      <div className="container-scroller rtl">
      <Navbar />

      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
          <Routes>
                <Route path="/" element={<DashboardAll />}  />
                <Route path="/add-disease" element={<AddDisease />}  />
                <Route path="/diseases" element={<Diseases />}  />
                <Route path="/disease/:id" element={<Disease />}  />

                <Route path="/add-product" element={<AddProduct />}  />
                <Route path="/products" element={<Products />}  />
                <Route path="/product/:id" element={<Product />}  />

                <Route path="/add-plant" element={<AddPlant />}  />
                <Route path="/add-plant-info/:id" element={<AddInfoPlant />}  />
                <Route path="/add-plant-propagation/:id" element={<AddPropagation />}  />
                <Route path="/plants" element={<Plants />}  />
                <Route path="/plant/:id" element={<Plant />}  />

                <Route path="/faq-plant/:idPlant" element={<FaqTest />}  />
                <Route path="/faq-disease/:idDisease" element={<FaqTest />}  />
                <Route path="/faq-product/:idProduct" element={<FaqTest />}  />

              </Routes>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
    </div>
</>

    );
  }


export default Dashboard;